import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layout';
import config from '../../data/SiteConfig';

const ContactPage = () => (
  <Layout>
    <main>
      <Helmet title={`Contact | ${config.siteTitle}`} />
      <h1>お問い合わせ</h1>
      <p>
        <a href="https://twitter.com/excelspeedup">twitter @excelspeedup</a>{' '}
        よりDMでお問い合わせください。
      </p>
    </main>
  </Layout>
);
export default ContactPage;
